import React, { ReactElement, useEffect } from 'react';

import Cursor from '../Cursor/Cursor';
import HamburgerNavigation from '../HamburgerNavigation/HamburgerNavigation';

import Navigation from '../Navigation/Navigation';

export interface LayoutProps {
	children: ReactElement[];
}

const Layout: React.FC<LayoutProps> = ({ children }): ReactElement => (
	<div>
		<Cursor />
		<header className='u-header'>
			<Navigation />
			<HamburgerNavigation />
		</header>

		<main className='u-main'>{children}</main>
	</div>
);

export default Layout;
