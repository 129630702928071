import React, { ReactElement } from 'react';

import TransitionLink from '../TransitionLink/TransitionLink';

import Logo from '../../assets/images/svg/logo.svg';

const Navigation: React.FC = (): ReactElement => (
	<nav className='o-navigation'>
		<ul className='o-navigation__items u-wrapper'>
			<li className='o-navigation__item o-navigation__logo'>
				<TransitionLink to='/' cn='o-navigation__link'>
					<h1>
						<Logo />
						<span className='u-hidden'>Louise Vanhoucke</span>
					</h1>
				</TransitionLink>
			</li>
			<li className='o-navigation__item'>
				<TransitionLink to='/' cn='o-navigation__link'>Work</TransitionLink>
			</li>
			<li className='o-navigation__item'>
				<TransitionLink to='/about' cn='o-navigation__link'>About</TransitionLink>
			</li>
		</ul>
	</nav>
);

export default Navigation;
