import React, { ReactElement, useEffect, useState } from 'react';
import cx from 'classnames';

import { isMobile } from '../../utils/detectMobile';

const Cursor: React.FC = (): ReactElement | null => {
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [clicked, setClicked] = useState(false);
	const [linkHovered, setLinkHovered] = useState(false);
	const [hidden, setHidden] = useState(false);

	useEffect(() => {
		addEventListeners();
		return () => removeEventListeners();
	}, []);

	const addEventListeners = () => {
		document.addEventListener('mousemove', onMouseMove);
		document.addEventListener('mouseenter', onMouseEnter);
		document.addEventListener('mouseleave', onMouseLeave);
		document.addEventListener('mousedown', onMouseDown);
		document.addEventListener('mouseup', onMouseUp);

		document.querySelectorAll('a').forEach((el) => {
			el.addEventListener('mouseover', () => setLinkHovered(true));
			el.addEventListener('mouseout', () => setLinkHovered(false));
		});

		document.querySelectorAll('button').forEach((el) => {
			el.addEventListener('mouseover', () => setLinkHovered(true));
			el.addEventListener('mouseout', () => setLinkHovered(false));
		});
	};

	const removeEventListeners = () => {
		document.removeEventListener('mousemove', onMouseMove);
		document.removeEventListener('mouseenter', onMouseEnter);
		document.removeEventListener('mouseleave', onMouseLeave);
		document.removeEventListener('mousedown', onMouseDown);
		document.removeEventListener('mouseup', onMouseUp);

		document.querySelectorAll('a').forEach((el) => {
			el.removeEventListener('mouseover', () => setLinkHovered(true));
			el.removeEventListener('mouseout', () => setLinkHovered(false));
		});

		document.querySelectorAll('button').forEach((el) => {
			el.removeEventListener('mouseover', () => setLinkHovered(true));
			el.removeEventListener('mouseout', () => setLinkHovered(false));
		});
	};

	const onMouseMove = (e: MouseEvent) => setPosition({ x: e.clientX, y: e.clientY });

	const onMouseDown = () => setClicked(true);

	const onMouseUp = () => setClicked(false);

	const onMouseLeave = () => setHidden(true);

	const onMouseEnter = () => setHidden(false);

	const renderCursor = (): ReactElement => (
		<aside
			className={cx('a-cursor', {
				'a-cursor--clicked': clicked,
				'a-cursor--hidden': hidden,
				'a-cursor--link-hovered': linkHovered
			})}
			style={{ left: `${position.x}px`, top: `${position.y}px` }}
		/>
	);

	return typeof window !== 'undefined' && typeof window.navigator !== 'undefined' && !isMobile() ? renderCursor() : null;
};

export default Cursor;
