import { useStaticQuery, graphql } from 'gatsby';

export default () => {
	const { allContentfulProject } = useStaticQuery(
		graphql`
			query IndexPageQuery {
				allContentfulProject(sort: {fields: updatedAt}) {
					edges {
						node {
							id
							title
							slug
							coverImage {
								desktop {
									file {
										url
									}
									fluid(
										resizingBehavior: FILL,
										quality: 100
									) {
										...GatsbyContentfulFluid_withWebp
									}
								}
								mobile {
									file {
										url
									}
									fluid(
										resizingBehavior: FILL,
										quality: 100
									) {
										...GatsbyContentfulFluid_withWebp
									}
								}
							}
						}
					}
				}
			}
		`
	);

	return allContentfulProject.edges;
};
