import React, { ReactElement, useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import Div100vh from 'react-div-100vh';

import Logo from '../../assets/images/svg/logo.svg';
import { setLock } from '../../utils/lockScreen';

import TransitionLink from '../TransitionLink/TransitionLink';
import { animateIn, animateOut } from './HamburgerNavigation.animations';

const HamburgerNavigation: React.FC = (): ReactElement => {
	const content = useRef<HTMLDivElement | null>(null);
	const [isToggled, setIsToggled] = useState<boolean>(false);
	const [isFirst, setIsFirst] = useState(true);
	const [hide, setHide] = useState(true);

	const toggle = (): void => {
		setIsFirst(false);
		toggleNav();
	};

	const toggleNav = (): void => setIsToggled((prev: boolean) => !prev);

	const onAnimationCompleted = (): void => setHide(true);

	useEffect(() => {
		if (content && content.current && !isFirst) {
			if (isToggled) {
				animateIn(content.current);
				setHide(false);
				setLock(true);
			}

			if (!isToggled) {
				animateOut(content.current, onAnimationCompleted);
				setLock(false);
			}
		}

		return () => setLock(false);
	}, [isToggled]);

	return (
		<nav className={cx('o-hamburger', isToggled && 'is-open')}>
			<div className='o-hamburger__top u-wrapper'>
				<TransitionLink to='/' cn='o-hamburger__link'>
					<h1>
						<Logo />
						<span className='u-hidden'>Louise Vanhoucke</span>
					</h1>
				</TransitionLink>
				<button
					className='o-hamburger__btn'
					type='button'
					onClick={toggle}
				>
					<div className='o-hamburger__bars'>
						<span className='o-hamburger__bar-top' />
						<span className='o-hamburger__bar-bottom' />
					</div>
				</button>
			</div>

			<Div100vh className={cx('o-hamburger__content-wrapper', hide && 'u-hidden')}>
				<div className='o-hamburger__content' ref={content}>
					<ul className='o-hamburger__items u-wrapper'>
						<li className='o-hamburger__item' onClick={toggle}>
							<TransitionLink to='/' cn='o-hamburger__link u-heading-medium'><p className='stagger'>Work</p></TransitionLink>
						</li>
						<li className='o-hamburger__item' onClick={toggle}>
							<TransitionLink to='/about' cn='o-hamburger__link u-heading-medium'><p className='stagger'>About</p></TransitionLink>
						</li>
					</ul>
				</div>
			</Div100vh>
		</nav>
	);
};

export default HamburgerNavigation;
