import React, { ReactElement, ReactNode } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

export interface TransitionLinkProps {
	to: string;
	cn?: string;
	children: ReactNode;
}

const TransitionLink: React.FC<TransitionLinkProps> = ({ to, cn, children }): ReactElement => (
	<AniLink cover direction='down' duration={1.2} bg='#c49fef' to={to} className={cn}>{children}</AniLink>
);

export default TransitionLink;
