import { useStaticQuery, graphql } from 'gatsby';

export default () => {
	const { allContentfulPerson } = useStaticQuery(
		graphql`
			query AboutPageQuery {
				allContentfulPerson {
					edges {
						node {
							childContentfulPersonAboutRichTextNode {
								json
							}
							contact {
								title
								url
								id
							}
							lottieAnimation {
								internal {
									content
								}
							}
						}
					}
				}
			}
		`
	);

	return allContentfulPerson.edges[0].node;
};
