import gsap from 'gsap';

import { animateStagger, helpersAnimation } from '../../utils/helpersAnimation';

export const animateIn = ($el: HTMLDivElement): void => {
	const items = $el.querySelector('.o-hamburger__items');

	gsap.fromTo($el,
		{
			y: '-100%',
		},
		{
			y: 0,
			duration: helpersAnimation.duration,
			ease: helpersAnimation.ease,
		});

	animateStagger(items as HTMLElement);
	gsap.fromTo(items,
		{
			opacity: 0,
		},
		{
			opacity: 1,
			duration: helpersAnimation.duration,
			ease: helpersAnimation.ease,
		});
};

export const animateOut = ($el: HTMLDivElement, onAnimationCompleted: () => void): void => {
	const items = $el.querySelector('.o-hamburger__items');
	gsap.fromTo(items,
		{
			opacity: 1,
		},
		{
			opacity: 0,
			duration: helpersAnimation.duration,
			ease: helpersAnimation.ease,
		});

	gsap.fromTo($el,
		{
			y: '0',
		},
		{
			y: '-100%',
			duration: helpersAnimation.duration,
			ease: helpersAnimation.ease,
			delay: .4,
			onComplete: () => onAnimationCompleted()
		});
};
